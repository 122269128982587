import ConfirmDialog from "components/Dialog/ConfirmDialog";
import Table from "components/Table/Table";
import { FOTAJobListView, FOTAJobRetryResponse } from "Device/Device";
import {
  uidFormatter,
  isOnlineFormatter,
  fotaJobStateFormatter,
  DateTime,
} from "Device/formatters";
import { Installed } from "Device/jobState";

import {
  postCancelFailedJobs,
  postCompleteConfirmJobs,
  useFetchAllJoblist,
} from "Device/requests";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import Button from "components/Button";
import { useActionState } from "./reducer";
import { toNumber } from "lodash";

import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  warningTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const ListInstalledJobs = () => {
  const [t] = useTranslation(["di", "app", "device"]);
  const filterGroups = {
    state: {
      label: t("fota.state"),
      options: {
        [Installed]: "INSTALLED",
      },
    },
  };
  const [state, setSelected] = useActionState<FOTAJobRetryResponse>();
  const [openCompleteDialog, setOpenCompleteDialog] = useState<boolean>(false);
  const classes = useStyles();
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  const { fetchFotaAllJobList } = useFetchAllJoblist([Installed]);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancelConfirm = async () => {
    setOpenCancelDialog(false);
    const result = await postCancelFailedJobs(state.selected.map(toNumber));
    const failCount = result.filter((i) => !i.successful).length;
    const passCount = result.filter((i) => i.successful).length;

    if (failCount > 0 && passCount > 0) {
      enqueueSnackbar(
        t("di:fota.fotaCancelJobsToastsMessages.partiallyCompleted", {
          failCount: failCount.toString(),
          passCount: passCount.toString(),
        }),
        {
          variant: "warning",
        }
      );
    } else if (failCount == 0 && passCount > 0) {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.success"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.failed"), {
        variant: "error",
      });
    }
  };

  const handleCompleteConfirmJobs = async () => {
    setOpenCancelDialog(false);
    const result = await postCompleteConfirmJobs(state.selected.map(toNumber)),
      failCount = result.filter((i) => !i.successful).length,
      passCount = result.filter((i) => i.successful).length;
    if (failCount > 0 && passCount > 0) {
      enqueueSnackbar(
        t(
          "di:fota.fotaCompleteInstalledJobsToastsMessages.partiallyCompleted",
          {
            failCount: failCount.toString(),
            passCount: passCount.toString(),
          }
        ),
        {
          variant: "warning",
        }
      );
    } else if (failCount == 0 && passCount > 0) {
      enqueueSnackbar(
        t("di:fota.fotaCompleteInstalledJobsToastsMessages.success"),
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(
        t("di:fota.fotaCompleteInstalledJobsToastsMessages.failed"),
        {
          variant: "error",
        }
      );
    }
  };

  const columns = useMemo<Column<FOTAJobListView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "is_online",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:fota.createdBy"),
        accessor: "created_by",
      },
      {
        Header: t<string>("di:fota.firmwareVersion"),
        accessor: "version",
      },
      {
        Header: t<string>("di:fota.state"),
        accessor: (v) => v,
        Cell: ({ value }: { value: FOTAJobListView }) => (
          <>{fotaJobStateFormatter({ value: value.state || "" })}</>
        ),
      },
      {
        Header: t<string>("di:fota.lastChange"),
        accessor: "updated_at",
        Cell: DateTime,
      },
    ],
    [t]
  );

  return (
    <>
      <Table<FOTAJobListView>
        columns={columns}
        onFetchData={fetchFotaAllJobList}
        autoRefresh={true}
        filterGroups={filterGroups}
        selectionEnabled
        onSelectionChanged={setSelected}
      />
      <Button
        color="primary"
        onClick={() => {
          setOpenCompleteDialog(true);
        }}
        className={classes.button}
        disabled={!state.selected.length}
      >
        {t("device.fotaJobs.complete")}
      </Button>
      <Button
        color="warning"
        onClick={() => {
          setOpenCancelDialog(true);
        }}
        className={classes.button}
        disabled={!state.selected.length}
      >
        {t("device.fotaJobs.cancel")}
      </Button>
      <ConfirmDialog
        onConfirm={handleCompleteConfirmJobs}
        open={openCompleteDialog}
        setOpen={setOpenCompleteDialog}
      >
        <h5>{t("device.fotaJobs.confirmCompleteInstalledJobs")}</h5>
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={handleCancelConfirm}
        open={openCancelDialog}
        setOpen={setOpenCancelDialog}
      >
        <h5>{t("device.fotaJobs.confirmCancelFailedJobs")}</h5>
      </ConfirmDialog>
    </>
  );
};
export default ListInstalledJobs;
