//TODO fix the state names once the update phase implementation is finalized
export const DownloadQueuedState = "DownloadQueued";
export const DownloadingState = "Downloading";
export const DownloadReadyState = "DownloadReady";
export const DownloadTimeoutState = "DownloadTimeout";
export const UpdateQueuedState = "UpdateQueued";
export const UpdatingState = "Updating";
export const UpdateTimeoutState = "UpdateTimeout";
export const ErrorState = "Error";
export const Installed = "Installed";
export const CompleteState = "Complete";

export type SupportedStates =
  | typeof DownloadQueuedState
  | typeof DownloadingState
  | typeof DownloadReadyState
  | typeof DownloadTimeoutState
  | typeof UpdateQueuedState
  | typeof UpdatingState
  | typeof UpdateTimeoutState
  | typeof ErrorState
  | typeof Installed
  | typeof CompleteState;

const jobStates: Record<SupportedStates, string> = {
  [DownloadQueuedState]: "DOWNLOAD QUEUED",
  [DownloadingState]: "DOWNLOADING",
  [DownloadReadyState]: "DOWNLOAD READY",
  [DownloadTimeoutState]: "DOWNLOAD TIMEOUT",
  [UpdateQueuedState]: "UPDATE QUEUED",
  [UpdatingState]: "UPDATING",
  [UpdateTimeoutState]: "UPDATE TIMEOUT",
  [ErrorState]: "ERROR",
  [Installed]: "INSTALLED",
  [CompleteState]: "COMPLETE",
};

export default jobStates;
