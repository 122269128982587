import { makeStyles, Tooltip } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { ErrorCodeTooltip } from "Device/Fota/jobsList/ListErrorJobs";
import { FetchDevicePageOptions } from "Device/definitions";
import {
  DateTime,
  fotaJobStateFormatter,
  isOnlineFormatter,
  uidFormatter,
} from "Device/formatters";
import { ErrorState } from "Device/jobState";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import Table, { PageResult } from "components/Table/Table";
import { FOTAJobListView } from "Device/Device";

interface ListFOTAJobsProps {
  filterGroups: {
    state: {
      label: string;
      options: Record<string, string>;
    };
  };
  fetchJobList: ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    selectedFilters,
  }: FetchDevicePageOptions) => Promise<PageResult<FOTAJobListView[]>>;
}
const useStyles = makeStyles((theme) => ({
  warningTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
}));
const ListFotaJobs: React.FC<ListFOTAJobsProps> = ({
  filterGroups,
  fetchJobList,
}) => {
  const [t] = useTranslation(["di", "app"]);
  const classes = useStyles();
  const columns = React.useMemo<Column<FOTAJobListView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "is_online",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:fota.createdBy"),
        accessor: "created_by",
      },
      {
        Header: t<string>("di:fota.firmwareVersion"),
        accessor: "version",
      },
      {
        Header: t<string>("di:fota.state"),
        accessor: (v) => v,
        Cell: ({ value }: { value: FOTAJobListView }) => (
          <>
            <>
              {value.state === ErrorState && value.error_code ? (
                <Tooltip
                  arrow
                  classes={{ tooltip: classes.warningTooltip }}
                  title={<ErrorCodeTooltip row={value} />}
                >
                  <ErrorIcon color="error" fontSize="inherit" />
                </Tooltip>
              ) : null}
              {fotaJobStateFormatter({ value: value.state || "" })}
            </>
          </>
        ),
      },
      {
        Header: t<string>("di:fota.lastChange"),
        accessor: "updated_at",
        Cell: DateTime,
      },
    ],
    [t]
  );
  return (
    <>
      <Table<FOTAJobListView>
        columns={columns}
        onFetchData={fetchJobList}
        initialSelectedRowIds={[]}
        autoRefresh={true}
        filterGroups={filterGroups}
      />
    </>
  );
};

export default ListFotaJobs;
