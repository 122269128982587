import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { AxiosResponse } from "axios";
import CustomInputField from "components/Form/CustomInputField";
import FormCheckbox from "components/Form/FormCheckbox";
import FormRadioGroup from "components/Form/FormRadioGroup";
import FormSelect, { Item } from "components/Form/FormSelect";
import {
  Ba,
  Calibration,
  CalibrationSchema,
  JumperBox,
  ResistanceBox,
  ResistanceBoxLabel,
} from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import PreFlightStepBase from "Device/Calibration/PreFlightStepBase";
import { Device } from "Device/Device";
import { baFormatter } from "Device/formatters";
import { getZklRcCalibration, updateMustHaveAftc } from "Device/requests";
import { ZKL_3000_RC, ZKL_3000_RC_AFTC } from "Device/supportedTypes";
import { Field } from "formik";
import useResponseValidator from "hooks/ResponseValidator";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import useAsyncEffect from "use-async-effect";
import { boolean, number, string } from "yup";
import { ObjectShape } from "yup/lib/object";
import * as semver from "semver";
import Grid from "@material-ui/core/Grid";

type Props = {
  device: Device | null;
  setDevice?: Dispatch<SetStateAction<Device | null>>;
  composition: Composition | null;
  setComposition: React.Dispatch<React.SetStateAction<Composition | null>>;
  resistanceBox: ResistanceBox | null;
  setResistanceBox: (rb: ResistanceBox | null) => void;
  jumperBox: JumperBox | null;
  setJumperBox: (jb: JumperBox | null) => void;
  ba: Ba;
  setBa: (ba: Ba) => void;
  dropOff: string;
  setDropOff: (dropOff: string) => void;
} & Partial<StepWizardChildProps>;

const ResistanceDropOff = 10;
const ZklRcPreFlightStep = ({
  device,
  setDevice,
  composition,
  setComposition,
  resistanceBox,
  setResistanceBox,
  jumperBox,
  setJumperBox,
  ba,
  setBa,
  dropOff,
  setDropOff,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const { validate } = useResponseValidator();
  const [mustHaveAftc, setAftcPresent] = useState<string>("");
  const [resistanceBoxes, setResistanceBoxes] = useState<ResistanceBox[]>([]);
  const [jumperBoxes, setJumperBoxes] = useState<JumperBox[]>([]);
  const [acceptExcessiveBaValue, setAcceptExcessiveBaValue] =
    useState<boolean>(false);
  const [diversityAntennaCheck, setDiversityAntennaCheck] =
    useState<string>("");

  const getCalibration = useCallback(async () => {
    if (!device) return;

    try {
      const response = await getZklRcCalibration(device.uid);
      const { resistances, calibration, jumpers } = await validate<Calibration>(
        CalibrationSchema,
        response
      );

      setResistanceBoxes(resistances);
      setJumperBoxes(jumpers);
      setBa(calibration.ba);
    } catch (e) {
      // Caught by interceptor
    }
  }, [device, setResistanceBoxes, setJumperBoxes, setBa]);

  const onSubmit = useCallback<() => Promise<AxiosResponse | void>>(() => {
    if (!canHaveAftc)
      return new Promise((resolve) => {
        resolve();
      });

    return updateMustHaveAftc(uid, mustHaveAftc === t("common:general.yes"));
  }, [device, mustHaveAftc]);

  useEffect(() => {
    if (!device) {
      setAcceptExcessiveBaValue(false);
      return;
    }
  }, [device, setAcceptExcessiveBaValue]);

  useAsyncEffect(async () => {
    if (!device) {
      return;
    }

    await getCalibration();
  }, [device, getCalibration]);

  if (!device) return <></>;

  const { type, uid } = device;
  const hasConfigurableServiceInterval = [
    ZKL_3000_RC,
    ZKL_3000_RC_AFTC,
  ].includes(type);
  const canHaveAftc = [ZKL_3000_RC, ZKL_3000_RC_AFTC].includes(type);

  const ncmVersionTransformed = semver.coerce(
    composition?.composition.ncmFirmware
  );

  const initialValues: {
    mustHaveAftc?: string;
    resistanceBox: string;
    jumperBox: string;
    baValue: number | string;
    acceptExcessiveBaValue: boolean;
    dropOff: string;
    diversityAntennaCheck?: string;
  } = {
    resistanceBox: resistanceBox?.label ?? "",
    jumperBox: jumperBox ?? "",
    baValue: baFormatter(ba),
    acceptExcessiveBaValue,
    dropOff,
  };
  const schemaShape: ObjectShape = {
    resistanceBox: string().required(),
    jumperBox: string().required(),
    baValue: number().required(),
    acceptExcessiveBaValue: boolean().equals([true]),
  };

  if (canHaveAftc) {
    initialValues.mustHaveAftc = mustHaveAftc;
    schemaShape.mustHaveAftc = string()
      .equals(
        device.type == "zkl-3000-rc-aftc"
          ? [t("common:general.yes")]
          : [t("common:general.no")],
        t("device.calibration.preFlightStep.aftcBoardError")
      )
      .required();
  }

  if (
    composition != null &&
    semver.valid(ncmVersionTransformed) &&
    ncmVersionTransformed != null
  ) {
    const diversityAntennaRequired =
      semver.gte(ncmVersionTransformed, "2.1.4") &&
      composition.ncmType == "dncm";
    const diversityAntennaRequiredNMDIcheck =
      semver.gte(ncmVersionTransformed, "1.1.0") &&
      composition.ncmType == "nmdi";
    initialValues.diversityAntennaCheck = diversityAntennaCheck;
    if (diversityAntennaRequired || diversityAntennaRequiredNMDIcheck) {
      schemaShape.diversityAntennaCheck = string()
        .equals(
          [t("common:general.yes")],
          t(
            "device.calibration.preFlightStep.diversityAntennaNotInstalledError",
            {
              type: composition.ncmType.toUpperCase(),
            }
          )
        )
        .required();
    } else {
      schemaShape.diversityAntennaCheck = string()
        .equals(
          [t("common:general.no")],
          t("device.calibration.preFlightStep.diversityAntennaInstalledError")
        )
        .required();
    }
  }

  const onResistanceBoxChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    for (const rb of resistanceBoxes) {
      if (rb.label === e.target?.value) {
        setDropOff(`${rb.mOhm + ResistanceDropOff}`);
        setResistanceBox(rb);
      }
    }
  };

  const onJumperBoxChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    for (const jb of jumperBoxes) {
      if (jb === e.target?.value) {
        setJumperBox(jb);
      }
    }
  };

  if (!device) return <></>;

  return (
    <PreFlightStepBase
      device={device}
      setDevice={setDevice}
      composition={composition}
      setComposition={setComposition}
      initValues={initialValues}
      initTouched={{ mustHaveAftc: true, diversityAntennaCheck: true }}
      schemaShape={schemaShape}
      hasConfigurableServiceInterval={hasConfigurableServiceInterval}
      onSubmit={[onSubmit]}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      {({ isSubmitting, handleChange }) => (
        <>
          <Grid container>
            {canHaveAftc && (
              <Grid item xs={12}>
                <FormRadioGroup
                  controlName="mustHaveAftc"
                  controlLabelText={t(
                    "device.calibration.preFlightStep.aftcPresent"
                  )}
                  options={[t("common:general.yes"), t("common:general.no")]}
                  onChange={async (
                    e: React.ChangeEvent<{
                      name?: string;
                      value: string;
                    }>
                  ) => {
                    handleChange(e);
                    setAftcPresent(e.target.value);
                  }}
                  disabled={isSubmitting}
                />
              </Grid>
            )}

            {semver.valid(ncmVersionTransformed) && (
              <Grid item xs={12}>
                <FormRadioGroup
                  controlName="diversityAntennaCheck"
                  controlLabelText={t(
                    "device.calibration.preFlightStep.diversityAntennaCheck"
                  )}
                  options={[t("common:general.yes"), t("common:general.no")]}
                  onChange={async (
                    e: React.ChangeEvent<{
                      name?: string;
                      value: string;
                    }>
                  ) => {
                    handleChange(e);
                    setDiversityAntennaCheck(e.target.value);
                  }}
                  disabled={isSubmitting}
                />
              </Grid>
            )}
          </Grid>

          <FormGroup>
            <FormLabel component="h3">
              {t("device.calibration.preFlightStep.zklRc.selectUsedTools")}
            </FormLabel>

            <FormSelect
              label={t("device.calibration.zklRc.resistanceBox")}
              name="resistanceBox"
              items={resistanceBoxes.map((rb) => {
                return {
                  id: rb.label,
                  label: ResistanceBoxLabel(rb),
                };
              })}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string;
                  value: unknown;
                }>
              ) => {
                handleChange(e);
                onResistanceBoxChange(e);
              }}
            />

            <FormSelect
              label={t("device.calibration.zklRc.switchBox")}
              name="jumperBox"
              items={jumperBoxes.map<Item>((value) => {
                return { id: value, label: value };
              })}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string;
                  value: unknown;
                }>
              ) => {
                handleChange(e);
                onJumperBoxChange(e);
              }}
            />
          </FormGroup>
          <Field
            component={CustomInputField}
            labelText={t("device.calibration.zklRc.baAutocalValue")}
            id="baValuePreFlight"
            name="baValue"
            disabled={true}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              autoComplete: "off",
            }}
          />
          <Field
            labelText={t("device.calibration.zklRc.calibrationDropOff")}
            component={CustomInputField}
            name="dropOff"
            id="dropOffPreFlight"
            disabled={true}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">mOhm</InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">{"<"}</InputAdornment>
              ),
            }}
            labelProps={{
              shrink: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
          <FormCheckbox
            label={t(
              "device.calibration.preFlightStep.zklRc.acceptExcessiveBaValue"
            )}
            name="acceptExcessiveBaValue"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              setAcceptExcessiveBaValue(e.target?.checked === true);
            }}
          />
        </>
      )}
    </PreFlightStepBase>
  );
};

export default ZklRcPreFlightStep;
