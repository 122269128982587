//todo fix the state names once the update phase implementation is finalized

import {
  DownloadTimeoutState,
  ErrorState,
  UpdateTimeoutState,
} from "Device/jobState";

export type ErroneousStates =
  | typeof DownloadTimeoutState
  | typeof UpdateTimeoutState
  | typeof ErrorState;

const erroneousJobStates: Record<ErroneousStates, string> = {
  [DownloadTimeoutState]: "DOWNLOAD TIMEOUT ",
  [UpdateTimeoutState]: "UPDATE TIMEOUT ",
  [ErrorState]: "ERROR ",
};

export default erroneousJobStates;
