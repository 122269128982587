import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";

import Table from "components/Table/Table";
import Loader from "components/Loader";

import { FOTAJobListView } from "Device/Device";

import { DownloadReadyState } from "Device/jobState";
import { useFetchAllJoblist, postCancelDownloadedJobs } from "Device/requests";
import { FotaJobCellCheckbox } from "Device/Service/CellCheckbox";
import { FotaJobHeaderCheckbox } from "Device/Service/HeaderCheckbox";
import { ToggleAllRowsSelectedFotaJobHook } from "Device/Service/ToggleAllRowsSelectedHook";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { useSnackbar } from "notistack";

import {
  DateTime,
  fotaJobStateFormatter,
  isOnlineFormatter,
  uidFormatter,
} from "Device/formatters";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  nextStep?: () => void;
  onSelectedJobs: (selectedRows: number[]) => void;
};

const SelectDownloadReadyStep = ({
  onSelectedJobs,
  nextStep,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation(["di", "app"]);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const { fetchFotaAllJobList } = useFetchAllJoblist([DownloadReadyState]);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onRowClick = (job: FOTAJobListView): boolean => {
    if (job.is_online) {
      return true;
    }
    return false;
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleCancelConfirm = async () => {
    setOpenCancelDialog(false);
    setLoading(true);
    const selectedJobsData = selected.map((id) => {
      const selectedJobs = Number(id);
      return selectedJobs;
    });
    const result = await postCancelDownloadedJobs(selectedJobsData);
    setLoading(false);
    const failCount = result.filter((i) => !i.successful).length;
    const passCount = result.filter((i) => i.successful).length;

    if (failCount > 0 && passCount > 0) {
      enqueueSnackbar(
        t("di:fota.fotaCancelJobsToastsMessages.partiallyCompleted", {
          failCount: failCount.toString(),
          passCount: passCount.toString(),
        }),
        {
          variant: "warning",
        }
      );
    } else if (failCount == 0 && passCount > 0) {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.success"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.failed"), {
        variant: "error",
      });
    }
  };

  const columns = React.useMemo<Column<FOTAJobListView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "is_online",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:fota.createdBy"),
        accessor: "created_by",
      },
      {
        Header: t<string>("di:fota.firmwareVersion"),
        accessor: "version",
      },
      {
        Header: t<string>("di:fota.state"),
        accessor: "state",
        Cell: fotaJobStateFormatter,
      },
      {
        Header: t<string>("di:fota.lastChange"),
        accessor: "updated_at",
        Cell: DateTime,
      },
    ],
    [t]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Table<FOTAJobListView>
        columns={columns}
        onFetchData={fetchFotaAllJobList}
        autoRefresh={true}
        selectionEnabled
        onSelectionChanged={setSelected}
        initialSelectedRowIds={selected}
        cellCheckbox={FotaJobCellCheckbox}
        headerCheckbox={FotaJobHeaderCheckbox}
        toggleAllRowsSelectedHooks={[ToggleAllRowsSelectedFotaJobHook]}
        onRowClick={onRowClick}
      />{" "}
      <span>
        <Button
          color="success"
          onClick={() => {
            setOpen(true);
          }}
          className={classes.button}
          disabled={selected.length == 0}
        >
          {t("device.fotaJobs.update")}
        </Button>
      </span>
      <span>
        <Button
          color="warning"
          onClick={() => {
            setOpenCancelDialog(true);
          }}
          className={classes.button}
          disabled={selected.length == 0}
        >
          {t("device.fotaJobs.cancel")}
        </Button>
      </span>
      <ConfirmDialog
        onConfirm={() => {
          setOpen(false);
          const selectedJobsData = selected.map((id) => {
            const selectedJobs = Number(id);
            return selectedJobs;
          });
          onSelectedJobs(selectedJobsData);
          if (nextStep) nextStep();
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{t("device.fotaJobs.confirmFotaUpdateReady")}</h5>
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={handleCancelConfirm}
        open={openCancelDialog}
        setOpen={setOpenCancelDialog}
      >
        <h5>{t("device.fotaJobs.confirmCancelFailedJobs")}</h5>
      </ConfirmDialog>
    </>
  );
};

export default SelectDownloadReadyStep;
