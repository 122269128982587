import { PageResult } from "components/Table/Table";
import TabPanel from "components/TabPanel";
import { FOTAJobListView } from "Device/Device";
import CreateFotaJob from "Device/Fota/create/CreateFotaJob";
import ListInstalledJobs from "Device/Fota/jobsList/ListInstalledJobs";
import UpdateReadyFotaJobs from "Device/Fota/updateReady/ReadyForUpdates";
import ListAllJobs from "Device/Fota/jobsList/ListAllJobs";
import ListErrorJobs from "Device/Fota/jobsList/ListErrorJobs";
import {
  Installed,
  DownloadReadyState,
  DownloadTimeoutState,
  ErrorState,
  UpdateTimeoutState,
} from "Device/jobState";
import { useFetchAllJoblist, useFetchFotaDevicePage } from "Device/requests";
import React, { useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Tabs, Tab } from "@material-ui/core";

type Props = {
  autoRefresh?: boolean;
};

const FotaJobPage = ({ autoRefresh = true }: Props): JSX.Element => {
  const [t] = useTranslation(["app"]);
  const { fetchFotaDevicePage } = useFetchFotaDevicePage();
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [tabCounts, setTabCounts] = useState<{ [key: string]: number }>({
    tab1: 0,
    tab2: 0,
    tab3: 0,
  });
  const changeTabPanel = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabPanel(newValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultUpdateReady: PageResult<FOTAJobListView[]> =
          await fetchAllUpdateReadyJobs({
            pageIndex: 0,
            pageSize: 1,
            sortBy: [],
            globalFilter: "",
            selectedFilters: {},
          });

        const resultFailed: PageResult<FOTAJobListView[]> =
          await fetchAllFailedJobs({
            pageIndex: 0,
            pageSize: 1,
            sortBy: [],
            globalFilter: "",
            selectedFilters: {},
          });

        const resultInstalled: PageResult<FOTAJobListView[]> =
          await fetchAllInstalledJobs({
            pageIndex: 0,
            pageSize: 1,
            sortBy: [],
            globalFilter: "",
            selectedFilters: {},
          });
        setTabCounts({
          tab1: resultUpdateReady?.rowCount || 0,
          tab2: resultFailed?.rowCount || 0,
          tab3: resultInstalled?.rowCount || 0,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    //Implementing the setInterval method to auto refresh
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const fetchDataCreatePage = async () => {
    await fetchFotaDevicePage({
      pageIndex: 0,
      pageSize: 10,
      sortBy: [],
      globalFilter: "",
      selectedFilters: {},
    });
  };
  return (
    <div>
      <Tabs value={tabPanel} onChange={changeTabPanel}>
        <Tab label={t("device.fotaJobs.createFota")} />\
        <Tab
          label={
            <CustomBadge
              count={tabCounts.tab1}
              label={t("device.fotaJobs.updateReady")}
            />
          }
        />
        \
        <Tab
          label={
            <CustomBadge
              count={tabCounts.tab2}
              label={t("device.fotaJobs.failed")}
            />
          }
        />
        \
        <Tab
          label={
            <CustomBadge
              count={tabCounts.tab3}
              label={t("device.fotaJobs.installed")}
            />
          }
        />
        \
        <Tab label={t("device.fotaJobs.allJobs")} />\
      </Tabs>
      <TabPanel value={tabPanel} index={0}>
        <CreateFotaJob
          actionTitle={t("device.fotaJobs.selectDevices")}
          selectDevicesTitle={t("device.fotaJobs.startDownloadWithCheck")}
          autoRefresh={autoRefresh}
          onUpdate={fetchDataCreatePage}
        />
      </TabPanel>
      <TabPanel value={tabPanel} index={1}>
        <UpdateReadyFotaJobs
          actionTitle={t("device.fotaJobs.selectDevices")}
          selectJobsTitle={t("device.fotaJobs.update")}
          autoRefresh={autoRefresh}
        />
      </TabPanel>
      <TabPanel value={tabPanel} index={2}>
        <ListErrorJobs />
      </TabPanel>
      <TabPanel value={tabPanel} index={3}>
        <ListInstalledJobs />
      </TabPanel>
      <TabPanel value={tabPanel} index={4}>
        <ListAllJobs />
      </TabPanel>
    </div>
  );
};
const { fetchFotaAllJobList: fetchAllUpdateReadyJobs } = useFetchAllJoblist([
  DownloadReadyState,
]);
const { fetchFotaAllJobList: fetchAllFailedJobs } = useFetchAllJoblist([
  DownloadTimeoutState,
  UpdateTimeoutState,
  ErrorState,
]);

const { fetchFotaAllJobList: fetchAllInstalledJobs } = useFetchAllJoblist([
  Installed,
]);

const CustomBadge: React.FC<{ count: number; label: string }> = ({
  count,
  label,
}) => (
  <Badge badgeContent={count} color="error" showZero={false}>
    {label}
  </Badge>
);
export default FotaJobPage;
