import { WebStorageStateStore } from "oidc-client-ts";
import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthProvider as ProviderOIDC } from "react-oidc-context";

import App from "App";
import "assets/scss/material-dashboard-pro-react.scss";
import "config/i18n";
import config from "config/WebSocket";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://1ea486ab07e7476189bd41dcab18d9ce@o304830.ingest.sentry.io/5441006",
    release: "device-maintenance@" + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
if (typeof process.env.REACT_APP_API_WSS_URL === "undefined") {
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  if (apiUrl.indexOf("http") !== -1) {
    config.url = apiUrl.replace("http", "ws");
  } else {
    config.url =
      (window.location.protocol === "https:" ? "wss://" : "ws://") +
      window.location.host +
      apiUrl;
  }
} else {
  config.url = `${process.env.REACT_APP_API_WSS_URL}`;
}
Object.freeze(config);

const publicUrl = new URL(
  process.env.PUBLIC_URL,
  window.location.origin
).toString();

const identityProvider = () =>
  process.env.REACT_APP_HOSTNAME &&
  window.location.hostname !== process.env.REACT_APP_HOSTNAME
    ? process.env.REACT_APP_IDP_STAGING_URL
    : process.env.REACT_APP_IDP_URL;

const root = document.getElementById("root");
if (root) {
  ReactDOM.render(
    <ProviderOIDC
      automaticSilentRenew={true}
      userStore={new WebStorageStateStore()}
      authority={identityProvider() || ""}
      client_id={process.env.REACT_APP_IDP_CLIENT_ID || ""}
      redirect_uri={publicUrl}
      post_logout_redirect_uri={publicUrl}
      scope="openid profile"
    >
      <App />
    </ProviderOIDC>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
