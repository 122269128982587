import ConfirmDialog from "components/Dialog/ConfirmDialog";
import Table from "components/Table/Table";
import { FOTAJobListView, FOTAJobRetryResponse } from "Device/Device";
import {
  uidFormatter,
  isOnlineFormatter,
  fotaJobStateFormatter,
  DateTime,
} from "Device/formatters";
import {
  DownloadTimeoutState,
  ErrorState,
  UpdateTimeoutState,
} from "Device/jobState";

import {
  postBulkRetryJobs,
  postCancelFailedJobs,
  useFetchAllJoblist,
} from "Device/requests";
import erroneousJobStates from "Device/Service/erroneousJobState";

import React, { createContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import Button from "components/Button";
import { defaultActionState, jobActionState, useActionState } from "./reducer";
import { toNumber } from "lodash";
import Alert from "@material-ui/lab/Alert";

import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import CheckTooltipDetail from "components/Fota/CheckTooltipDetail";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  warningTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const ListErrorJobs = () => {
  const [t] = useTranslation(["di", "app", "device"]);
  const filterGroups = {
    state: { label: t("fota.state"), options: erroneousJobStates },
  };
  const [state, setSelected, toggleConfirm, setResults] =
    useActionState<FOTAJobRetryResponse>();
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const classes = useStyles();

  const { fetchFotaAllJobList } = useFetchAllJoblist([
    DownloadTimeoutState,
    UpdateTimeoutState,
    ErrorState,
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancelConfirm = async () => {
    setOpenCancelDialog(false);
    const result = await postCancelFailedJobs(state.selected.map(toNumber));
    const failCount = result.filter((i) => !i.successful).length;
    const passCount = result.filter((i) => i.successful).length;

    if (failCount > 0 && passCount > 0) {
      enqueueSnackbar(
        t("di:fota.fotaCancelJobsToastsMessages.partiallyCompleted", {
          failCount: failCount.toString(),
          passCount: passCount.toString(),
        }),
        {
          variant: "warning",
        }
      );
    } else if (failCount == 0 && passCount > 0) {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.success"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t("di:fota.fotaCancelJobsToastsMessages.failed"), {
        variant: "error",
      });
    }
  };

  const columns = useMemo<Column<FOTAJobListView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "is_online",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:fota.createdBy"),
        accessor: "created_by",
      },
      {
        Header: t<string>("di:fota.firmwareVersion"),
        accessor: "version",
      },
      {
        Header: t<string>("di:fota.state"),
        accessor: (v) => v,
        Cell: ({ value }: { value: FOTAJobListView }) => (
          <>
            <RetryContext.Consumer>
              {(state) => {
                const retryWarning = state.results.find(
                  (i) => i.id == value.id
                );
                if (retryWarning && !retryWarning.successful) {
                  return (
                    <Tooltip
                      arrow
                      classes={{ tooltip: classes.warningTooltip }}
                      title={<WarningTooltip row={retryWarning} />}
                    >
                      <WarningIcon color="secondary" fontSize="inherit" />
                    </Tooltip>
                  );
                }
              }}
            </RetryContext.Consumer>{" "}
            <>
              {value.state === ErrorState && value.error_code ? (
                <Tooltip
                  arrow
                  classes={{ tooltip: classes.warningTooltip }}
                  title={<ErrorCodeTooltip row={value} />}
                >
                  <ErrorIcon color="error" fontSize="inherit" />
                </Tooltip>
              ) : null}
              {fotaJobStateFormatter({ value: value.state || "" })}
            </>
          </>
        ),
      },
      {
        Header: t<string>("di:fota.lastChange"),
        accessor: "updated_at",
        Cell: DateTime,
      },
    ],
    [t]
  );

  return (
    <>
      {state.results.length ? <RetryAlert results={state.results} /> : []}
      <RetryContext.Provider value={state}>
        <Table<FOTAJobListView>
          columns={columns}
          onFetchData={fetchFotaAllJobList}
          autoRefresh={true}
          filterGroups={filterGroups}
          selectionEnabled
          onSelectionChanged={setSelected}
        />
      </RetryContext.Provider>
      <Button
        color="primary"
        onClick={toggleConfirm}
        disabled={!state.selected.length}
        className={classes.button}
      >
        {t("device.fotaJobs.retry")}
      </Button>
      <Button
        color="warning"
        onClick={() => {
          setOpenCancelDialog(true);
        }}
        className={classes.button}
        disabled={!state.selected.length}
      >
        {t("device.fotaJobs.cancel")}
      </Button>
      <ConfirmDialog
        onConfirm={() => {
          setSelected([]);
          postBulkRetryJobs(state.selected.map(toNumber)).then((res) => {
            setResults(res);
          });
        }}
        open={state.confirm}
        setOpen={toggleConfirm}
      >
        <h5>{t("device.fotaJobs.retryConfirm")}</h5>
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={handleCancelConfirm}
        open={openCancelDialog}
        setOpen={setOpenCancelDialog}
      >
        <h5>{t("device.fotaJobs.confirmCancelFailedJobs")}</h5>
      </ConfirmDialog>
    </>
  );
};

const RetryContext = createContext<jobActionState<FOTAJobRetryResponse>>(
  defaultActionState()
);

// Give priority to failure reason than checks
const WarningTooltip = ({ row }: { row: FOTAJobRetryResponse }) => {
  const [t] = useTranslation(["di", "app"]);
  return (
    <>
      {!row.failureReason ? (
        <>
          <Typography style={{ paddingBottom: 5 }}>
            {t("device.fotaJobs.retryFailedByCheck")}
          </Typography>
          <CheckTooltipDetail result={row.checkDetail} t={t} />
        </>
      ) : (
        <Typography>{row.failureReason}</Typography>
      )}
    </>
  );
};

export const ErrorCodeTooltip = ({ row }: { row: FOTAJobListView }) => {
  const [t] = useTranslation(["di", "app"]);

  const getErrorMessage = (errorCode: string): string => {
    const translationKey = `device.fotaJobs.errors.${errorCode}`;
    const translatedMessage = t(translationKey);
    // If the translation key does not exist, it will return the key itself.
    // So we need to handle such cases to return a default error message.
    if (translatedMessage === translationKey) {
      return t("device.fotaJobs.errors.UNKNOWN"); // Default error message
    }
    return translatedMessage;
  };

  return (
    <>
      {row.state === ErrorState ? (
        row.error_code ? (
          <Typography style={{ paddingBottom: 5 }}>
            {getErrorMessage(row.error_code)}
          </Typography>
        ) : (
          <Typography style={{ paddingBottom: 5 }}>
            {"Failed to retrieve Error code. Check logs"}
          </Typography>
        )
      ) : null}
    </>
  );
};

const RetryAlert = ({ results }: { results: FOTAJobRetryResponse[] }) => {
  const [t] = useTranslation(["di", "app"]);
  const passed = results.filter((i) => i.successful).length;
  const failed = results.filter((i) => !i.successful).length;
  return (
    <Alert severity={failed > 1 ? "warning" : "info"}>
      {passed
        ? t("di:fota.fotaCheckPassedMessage", {
            count: passed,
          })
        : []}
      {failed
        ? t("di:fota.fotaCheckFailedMessage", {
            count: failed,
          })
        : []}
    </Alert>
  );
};

export default ListErrorJobs;
